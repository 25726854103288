/* -------------------------------------------
[Table of contents]

[Color codes]
    Background: fff;
    Body Color : #696767
    Accent color: var(--blue)

[Typography]
    Body:   14/24px 'Josefin Sans', sans-serif;
    Input:  14/40px
    Textarea:   14/24px

[Component module]

1.0 RESET BROWSER
	1.1 CUSTOM COLUMN
	1.2 BUIDER
	1.3 WORDPRESS DATA SAMPLE
    1.4 LOADING AJAX
    1.5 BANNER EFFECT
2.0 DEFAULT SETTING
    2.1 FORM THEME
    2.2 TABLE THEME
    2.3 CONTAINER THEME
    2.4 SLICK SLIDER
3.0 HEADER THEME
	3.1 HEADER TOP
    3.2 HEADER MENU
    3.3 BLOCK SEARCH
    3.4 HEADER CONTROL
    3.5 HEADER LOGO
    3.6 HEADER STICKY
    3.7 HEADER MIDDLE
    3.8 HEADER NAV
    3.9 HEADER STYLE
    3.10 HEADER MOBILE
4.0 FOOTER THEME
5.0 BLOG THEME
	5.1 POST ITEM
	5.2 BLOG COMMENT
6.0 BANNER + BREADCRUMBS + WDGET + PAGINATION
	6.1 BANNER AND TITLE PAGE
	6.2 BREADCRUMBS THEME
	6.3 WIDGET THEME
	6.4 PAGINATION THEME
	6.5 LOADMORE
7.0 SHOP THEME
    7.1 SHOP CONTROL
    7.2 PRODUCT ITEM
    7.3 SINGLE PRODUCT
8.0 OTHER PAGE
	8.1 CART PAGE
	8.2 CHECKOUT PAGE
	8.3 MY ACCOUNT PAGE
	8.4 WISHLIST PAGE
	8.5 TRACK YOUR ORDER
	8.6 PAGE 404
	8.7 POPUP NEWSLETTER
9.0 SHORTCODE
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.1  BANNER
	9.2  BLOG
	9.3  CATEGORY
	9.4  CUSTOMMENU
	9.5  HEADING
	9.6  ICONBOX
	9.7  INSTAGRAM
	9.8  LISTITEM
	9.9  MAP + CONTACT FORM 7
	9.10  TEAM
	9.11  NEWSLETTER
	9.12  POPUP VIDEO
	9.13  PRODUCTS
	9.14  SOCIAL
	9.15  TABS
	9.16  TESTIMONIAL
	9.17  VERTICALMENU
	9.20 ACCORDION
	9.21 INSTAGRAM SHOP
	9.22  PRICING
10.0 CUSTOM CLASS
/*==========================================================================
1.0 DEFAUL SETTING
========================================================================== */
:root {
    --black: #000;
    --green: #9cc25d;
    --blue: #0593DA;
    --gray: #696767;
    --white: #ffffff;
    --border: #ebebeb;
    --border-dark: #868686;
    --border-dotted: #f0f0f0;
    --body-dark-background: #f4f4f4;
    --tiles-background: #f8f8f8;
    --danger: #ec0000;
}
html {
    font-size: 62.5%;
}
body {
    font-size: 1.6rem;
}
body {
    max-width: 100%;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol {
    margin: 0;
    padding-left: 19px;
}

ul>li {
    list-style: none;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    margin-bottom: 20px;
}

figure {
    margin: 0;
}

.more-link::after {
    content: '\f178';
    font-family: 'FontAwesome', sans-serif;
    margin-left: 5px;
    vertical-align: middle;
}

.post-password-form input[type='submit']:hover {
    background-color: var(--blue);
}


input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='search'],
input[type='url'],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

select::-ms-expand {
    display: none;
}

input[type='text']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='tel']:focus,
input[type='url']:focus,
textarea:focus,
select:focus {
    outline: none;
}

:focus {
    outline: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
    filter: alpha(opacity=100);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
    opacity: 1;
    filter: alpha(opacity=100);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
    opacity: 1;
    filter: alpha(opacity=100);
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
    filter: alpha(opacity=100);
}

.added_to_cart.disabled,
.added_to_cart:disabled,
.button.disabled,
.button:disabled,
button.disabled,
button:disabled,
input[type='button'].disabled,
input[type='button']:disabled,
input[type='reset'].disabled,
input[type='reset']:disabled,
input[type='submit'].disabled,
input[type='submit']:disabled {
    opacity: 0.65 !important;
    cursor: not-allowed;
}


button.btn-1.btn-3.submit,
.btn-1 {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 10px;

    &:hover {
        background-color: var(--blue);
    }
}

input[type="number"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="search"],
input[type="url"],
textarea,
select {

    &:focus,
    &:hover {
        border: 1px solid var(--blue);
    }

    &.invalid_input {
        border: 1px solid var(--danger);
    }
}

.error_msg {
    color: var(--danger);
}

.alignnone {
    margin: 5px 20px 20px 0;
}

img.alignnone {
    margin: 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

iframe {
    max-width: 100% !important;
}

.alignright {
    float: right;
    margin: 5px 0 20px 30px;
}

.alignleft {
    float: left;
    margin: 5px 30px 20px 0;
}

/*---------------------------
1.4 LOADING AJAX
-------------------------------*/
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--blue);
    border-radius: 50%;
    border-top-color: var(--tiles-background);
    animation: spinner 0.6s linear infinite;
    margin: 0 auto;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*--------------------------
1.5 BANNER EFFECT
------------------------------*/

/*ANIMATION*/
@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}


/* ==========================================================================
2.0 DEFAULT SETTING
========================================================================== */
html,
body {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    line-height: 26px;
    color: var(--gray);
    overflow-x: hidden;
}

label {
    font-weight: 600;
    color: var(--black);
}

a {
    color: var(--black);
    text-decoration: none;
    -webkit-transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
    -moz-transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
    -o-transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
    transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: var(--blue);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    color: var(--black);
    margin-top: 12px;
    margin-bottom: 18px;
    font-weight: normal;
}

blockquote,
q {
    color: var(--black);
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    padding: 15px 0 15px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-left: 3px solid var(--blue);
}

dt,
dd {
    line-height: 30px;
}

/* =========================================================================
2.1 FORM THEME
============================================================================ */
input[type='number'],
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='search'],
input[type='url'],
textarea,
select {
    border: 1px solid #dadada;
    padding: 0 20px;
    max-width: 100%;
    background-color: transparent;
    font-size: 14px;
    color: var(--border-dark);
    height: 40px;
    line-height: 40px;
    border-radius: 0;
}

textarea {
    display: block;
    line-height: 24px;
    padding: 15px 20px;
    height: auto;
    width: 100%;
    border-radius: 0;
}

select {
    padding: 0 33px 0 15px;
    /* background: url(../images/select-arrow.png) center right no-repeat; */
}

button,
input[type='submit'],
input[type='button'],
.button,
a.button {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: var(--white);
    padding: 0 15px;
    background: var(--black);
    border: none;
    border-radius: 0;
    -webkit-transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
    -moz-transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
    -o-transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
    transition: color 0.25s, border-color 0.25s, background-color 0.25s,
        opacity 0.25s, width 0.25s ease 0s;
}


/* ==========================================================================
2.3 CONTAINER THEME
============================================================================ */
.container {
    max-width: 100%;
}

@media (min-width: 1280px) {
    .container {
        width: 1200px;
    }
}

@media (max-width: 1199px) {
    .row {
        margin-left: -10px;
        margin-right: -10px;
    }
}

@media (max-width: 767px) {
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
}

/* ==========================================================================
2.4 SLICK SLIDER
============================================================================ */
.slick-arrow {
    cursor: pointer;
    -webkit-transition: all 0.6s ease 0s;
    -moz-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s;
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    z-index: 2;
}

.slick-prev:before,
.slick-next:before {
    color: var(--black) !important;
}

.slick-prev {
    left: -2px !important;
}

.slick-next {
    right: -2px !important;
}

/*.slick-dots*/
body .slick-dots {
    padding-left: 0;
    list-style: none;
    text-align: center;
    height: 22px;
    margin-top: 40px;
    position: relative;
}

body .slick-dots:empty {
    display: none;
}

body .slick-dots li {
    display: inline-block;
    padding: 5px;
    height: 22px;
    width: auto;
}

body .slick-dots li button {
    font-size: 0;
    width: 9px;
    height: 9px;
    padding: 0;
    position: relative;
    background-color: var(--border-dark);
    border-radius: 12px;
}

body .slick-dots li button:before {
    display: none;
}

body .slick-dots li button:hover {
    background-color: var(--blue);
}

body .slick-dots li.slick-active button {
    background-color: var(--blue);
    width: 30px;
}

body .dots-light .slick-dots li.slick-active button,
body .dots-light .slick-dots li button:hover,
body .dots-light .slick-dots li button {
    background: var(--white);
}

.slick-vertical {
    padding-right: 45px;
}

.slick-vertical .slick-dots li.slick-active {
    height: 40px;
}

.slick-vertical .slick-dots li.slick-active button {
    background: var(--blue) !important;
    width: 9px;
    height: 30px;
}

.slick-vertical .slick-dots {
    margin-top: 0 !important;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: auto;
    height: auto;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

.slick-vertical .slick-dots li {
    display: block;
}

@media (max-width: 1199px) {
    body .slick-dots {
        margin-top: 25px;
    }
}

@media (max-width: 767px) {
    body .slick-dots {
        margin-top: 15px;
    }
}

.border-top-1 {
    border-top: 1px solid var(--boder);
}

.section-001 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.section-002 {
    padding-top: 75px;
    padding-bottom: 30px;
}

.section-003 .style-01.right-top:first-child {
    margin-bottom: 30px;
}

.section-007 {
    padding-bottom: 75px;
}

.section-010 {
    padding-top: 10px;
    padding-bottom: 10px;
    background: var(--body-dark-background);
    border-top: 1px solid var(--boder);
}

.section-010 p {
    margin-bottom: 0;
    padding: 18px 0;
}

.section-018 {
    padding-top: 190px;
    padding-bottom: 190px;
}

.section-039 {
    background-color: var(--body-dark-background);
}

@media (max-width: 1499px) {
    .section-001 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .section-002 {
        padding-top: 65px;
    }

    .section-018 {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

@media (max-width: 1199px) {
    .section-001 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .section-002 {
        padding-top: 55px;
    }

    .section-002 .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .section-002 .col-md-12.col-lg-4 {
        padding-right: 10px;
        padding-bottom: 20px;
        padding-left: 10px;
    }

    .section-002 .col-md-12.col-lg-4:last-child {
        padding-bottom: 0;
    }

    .section-003 .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .section-003 .col-md-12.col-lg-6 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .section-007 {
        padding-bottom: 55px;
    }

    .section-018 {
        padding-top: 90px;
        padding-bottom: 90px;
    }
}

@media (max-width: 991px) {
    .section-001 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .section-002 {
        padding-top: 45px;
    }

    .section-007 {
        padding-bottom: 45px;
    }

    .section-007 .col-md-12.col-lg-4:not(:last-child) {
        padding-bottom: 30px;
    }

    .section-018 {
        padding-top: 70px;
        padding-bottom: 70px;
        background-color: var(--body-dark-background);
    }
}

@media (max-width: 767px) {
    .section-001 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .section-002 {
        padding-top: 35px;
        padding-bottom: 10px;
    }

    .section-002 .col-md-12.col-lg-4 {
        padding-bottom: 10px;
    }

    .section-007 {
        padding-bottom: 35px;
    }

    .section-010 {
        padding-bottom: 10px;
    }

    .section-010 p {
        padding: 0;
        text-align: center;
    }

    .section-010 footer {
        text-align: center;
    }

    .section-018 {
        padding-top: 45px;
        padding-bottom: 45px;
    }
}


/* banner info green square label */
.left-center .banner-green-label {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.right-top .banner-green-label {
    right: 30px;
    position: absolute;
    top: 30px;
}

.left-bottom .banner-green-label {
    left: 30px;
    position: absolute;
    bottom: 30px;
}

/* ==========================================================================
6.2 BREADCRUMBS THEME
========================================================================== */
.breadcrumbs {
    width: 1800px;
    max-width: 100%;
    /* padding-left: 20px;
    padding-right: 20px; */
    margin: 15px auto 0;
    font-weight: 600;
}

body.single .breadcrumbs {
    text-align: left;
}

.breadcrumbs .breadcrumb {
    list-style: none;
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
    display: block;
}

.breadcrumbs .breadcrumb li {
    display: inline-block;
    color: var(--gray);
    font-weight: bold;
}

.breadcrumbs .breadcrumb li+li::before {
    font-family: 'FontAwesome', sans-serif;
    content: '\f105';
    color: var(--gray);
    padding-left: 13px;
    padding-right: 10px;
}

.breadcrumbs .breadcrumb li a:not(:hover) {
    color: var(--black);
}


/* ==========================================================================
6.1 BANNER AND TITLE PAGE
========================================================================== */
.main-container {
    padding-top: 40px;
    padding-bottom: 70px;
}

body.single .main-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.hero-banner-wrapper-inner {
    text-align: left;
}

.hero-banner-wrapper.has_background {
    position: relative;
}

.hero-banner-wrapper.has_background img {
    max-height: 400px;
    object-fit: cover;
    width: 100%;
}

.hero-banner-wrapper.has_background .hero-banner-wrapper-inner {
    padding-top: 5px;
}

/* hero background video */
.hero-video-wrapper {
    /* position: relative;
    height: 70vh; */
    height: 42.2em;
}
@media (min-width: 1240px) {
   .hero-video-wrapper {
       height: 42.2em;
    }
}
@media (max-width: 1239px) {
   .hero-video-wrapper {
        height: 32em;
    }
}

@media (max-width: 767px) { 
    .hero-video-wrapper {
        height: 56vmax;
    }
}

.hero-video-wrapper .video-bg {
    position: relative;
    height: 100%;
    background: var(--black);
    overflow: hidden;
}

.hero-video-wrapper .video-bg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    /* background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); */
} 

.hero-video-wrapper .video-bg iframe {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 60vw;
}

.page-title {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.page-title::before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: var(--blue);
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media (max-width: 767px) {
    .hero-banner-wrapper.has_background img {
        max-height: 400px;
        width: 100%;
    }
}

.article-title {
    font-size: 30px;
    font-weight: bold;
    padding-left: 20px;
    position: relative;
    margin-bottom: 30px;
    margin-top: 0;
    border-left: 2px solid var(--blue);
}

/* Full size banner */
.full-size-banner {
    height: 350px;
    margin-bottom: 20px;
    width: 100%;
}

.full-size-banner .banner-info {
    height: 100%;
}

.full-size-banner .banner-info .banner-content {
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    padding-left: 30px;
    max-width: 500px;
}

.full-size-banner .banner-label {
    font-size: 16px;
    letter-spacing: 0.6em;
    display: inline-block;
    color: var(--blue);
    text-transform: uppercase;
    white-space: nowrap;
    padding-left: 15px;
}

.full-size-banner .title {
    font-size: 46px;
    font-weight: 700;
    letter-spacing: 0.01em;
    margin-bottom: 15px;
    position: relative;
    color: var(--blue);
    padding-left: 12px;
}

.full-size-banner .title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 12px;
    height: 65px;
    border-left: 1px solid;
}

.full-size-banner .subtitle {
    font-size: 16px;
    margin-bottom: 23px;
    max-width: 500px;
}

.product-slider .slick-slide {
    padding: 10px;
}

.product-slider .slick-slide.slick-active>div {
    margin-bottom: 20px;
}

.product-slider .slick-prev,
.product-slider .slick-next {
    top: 49%;
}

.product-slider .slick-prev {
    left: -20px !important;
}

.product-slider .slick-next {
    right: -20px !important;
}

.product-slider .slick-slide .product-item-tile {
    margin-bottom: 0;
}

.product-slider .slick-dots {
    margin-top: 0 !important;
}

/* Footer */
footer.section-010 {
    position: relative;
}

button.backtotop {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    background-color: var(--blue);
    position: fixed;
    bottom: 65px;
    right: 25px;
    text-align: center;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    z-index: 999;
    overflow: hidden;
    border: 1px solid var(--white);
}

.floating-contactus,
.floating-enquiry {
    position: fixed;
    bottom: 120px;
    right: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    background-color: var(--blue);
    right: 25px;
    text-align: center;
    transition: all 0.4s ease 0s;
    z-index: 999;
    overflow: hidden;
    border: 1px solid var(--white);
}

.floating-contactus svg,
.floating-enquiry svg {
    color: var(--white);
    height: 40px;
    position: relative;
    width: 40px;
}

button.backtotop::before,
.floating-contactus:before,
.floating-enquiry:before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-45deg);
    -moz-transform: skewX(-45deg);
    -o-transform: skewX(-45deg);
    -ms-transform: skewX(-45deg);
    transform: skewX(-45deg);
}

button.backtotop:hover::before,
.floating-contactus:hover:before,
.floating-enquiry:before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}
.floating-enquiry {
    bottom: 175px;
}
.floating-enquiry svg {
    position: relative;
    left: -9px;
}

.section-010 .footer-social-links {
    text-align: right;
}

footer .social-title {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 600;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding-left: 45px;
}

footer .social-title::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 35px;
    border-bottom: 2px solid var(--blue);
}

footer .socials-list li {
    display: inline-block;
    height: 46px;
    margin-right: 10px;
    margin-top: 8px;
}

footer .socials-list li:last-child {
    margin-right: 0;
}

footer .socials-list li a {
    display: block;
    height: 46px;
    width: 46px;
    font-size: 17px;
    line-height: 48px;
    color: var(--white);
    background-color: var(--black);
    text-align: center;
    position: relative;
    overflow: hidden;
}

footer .socials-list li a::before {
    content: '';
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    bottom: -1px;
    -webkit-transition: top 0.25s ease 0s;
    -moz-transition: top 0.25s ease 0s;
    -o-transition: top 0.25s ease 0s;
    transition: top 0.25s ease 0s;
    background-color: var(--blue);
}

footer .socials-list li a:hover::before {
    top: -1px;
}

footer .socials-list li a:hover svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.section-007.contactus {
    margin-top: 40px;
}

.export .section-heading {
    padding-bottom: 20px !important;
}
.export-inner,
.testimonial {
    position: relative;
}

.testimonial-inner .desc {
    font-size: 24px;
    line-height: 40px;
    color: var(--black);
    margin-bottom: 15px;
    /* min-height: 250px; */
}

.testimonial-inner .desc:before {
    content: "\f10d";
    font-family: "FontAwesome", sans-serif;
    font-size: 32px;
    color: var(--blue);
    background: var(--white);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    margin-bottom: 3px;
}

.testimonial-inner .testimonial-info {
    text-align: right;
}

.testimonial-inner .testimonial-info .intro {
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
}

.testimonial-inner .testimonial-info .name {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--black);
}

.export-inner .desc {
    font-size: 24px;
    line-height: 40px;
    color: var(--black);
    margin-bottom: 15px;
    min-height: 250px;
}

body .export-slider .slick-dots {
    margin-top: 0;
    height: unset;
}  

.section-001.categories .slick-slide {
    padding: 10px;
}

.section-001.categories .slick-slide img {
    border-radius: 50%;
    margin: 0 auto;
}
.section-001.categories .slick-slide .categories-thumb {
    display: block;
    height: 200px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    font: 0/0 a;
    border-radius: 50%;
    border: 1px solid var(--body-dark-background);
}

.section-001.categories .slick-prev,
.section-001.categories .slick-next {
    top: 45%;
}

.categories-inner {
    text-align: center;
}

.section-001.categories .categories-thumb {
    border-radius: 50%;
    -webkit-transition: transform 1s ease 0s;
    -moz-transition: transform 1s ease 0s;
    -o-transition: transform 1s ease 0s;
    transition: transform 1s;
}

.categories-inner:hover .categories-thumb {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.page_subtitle {
    font-size: 30px;
    margin-bottom: 30px;
    margin-top: -5px;
    line-height: 1.2;
    color: var(--black);
}

h2.subtitle {
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: -5px;
    line-height: 1.2;
    color: var(--black);
}

.page_subtitle>div iframe {
    width: 100% !important;
}

.testimonial.section-018 {
    background-repeat: no-repeat;
    background-position: 70% center;
    background-size: cover;
}

.widget .widget-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
    position: relative;
    margin-top: -2px;
    z-index: 1;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border);
}

.widget ul>li {
    padding-top: 8px;
    padding-bottom: 8px;
}

.widget ul>li a {
    font-size: 16px;
    position: relative;
    font-weight: 600;
}

.widget ul>li a.active {
    color: var(--blue);
    font-weight: bold;
}

.widget ul {
    padding: 0;
}

.widget ul>li span {
    font-weight: 600;
    float: right;
    font-size: 14px;
    position: relative;
    font-weight: 600;
}

.products-item-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
}

.prod-column-3 .products-item-wrapper .product-item-tile {
    width: calc(33.33% - 20px)
} 
.prod-column-4 .products-item-wrapper .product-item-tile {
    width: calc(25% - 20px)
}
@media (max-width: 992px) {
    .prod-column-3  .products-item-wrapper .product-item-tile {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .prod-column-3  .products-item-wrapper .product-item-tile {
        width: calc(100%);
    }
}
.button-group {
    margin-top: 30px;
    text-align: center;
}

.button-group .button, a.button {
    margin: 0 auto;
    width: auto;
}

/* modal */
.equiry-modal::backdrop {
    background: hsl(0 0% 0% / 50%);
}

.equiry-modal {
    max-width: 20rem;
    padding: 2rem;
    border: 0;
    border-radius: 0.5rem;
    position: relative;
    border-radius: 1rem;
    position: fixed;
    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
    border: 1px solid #ccc;
}

.equiry-modal .modal-close-btn {
    font-size: 0.75em;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    height: auto;
    line-height: normal;
    background: transparent;
}

.equiry-modal .modal-close-btn svg {
    color: var(--black);
    height: 1.4rem;
    width: 1.5rem;
}

.equiry-modal .modal-close-btn:hover svg {
    color: var(--blue);
}

.entry-content {
    padding-left: 30px;
}

.entry-content > li {
    list-style-type: square;
}

.privacy-item {
    color: var(--blue);
}

ul.export-countries {
    display: flex;
    flex-flow: row nowrap;
    max-width: 1200px;
    overflow: auto;
}

ul.export-countries > li {
    width: 200px;
    margin-right: 20px;
    text-align: center;
}

ul.export-countries > li img {
    object-fit: cover;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
}

ul.export-countries > li b {
    font-size: 0.9rem;
}